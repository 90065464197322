.confirm-modal {
  z-index: 99999 !important;

  &__message {
    min-height: 120px;
  }

  .modal-footer .btn {
    min-width: 110px;
  }
}
