@import '../../../theme/common/variable';

.mainmenu-section {
  background-color: $bg-main-color;
}

.mainmenu-item {
  font-size: 1.5rem !important;
  min-width: 12rem !important;
}
