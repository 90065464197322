@import '../../../theme/common/variable';

.table-movement-history table th span {
  max-width: 3rem;
  display: inline-block;
  white-space: pre-wrap;
}

.table-movement-history table th span:nth-child(2) {
  display: flex;
  margin: auto;
  max-width: 3.5rem;
}

.table-movement-history table th {
  text-align: center;
  vertical-align: middle;
  font-size: 0.8125rem;
}
