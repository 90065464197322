@import '../../../theme/common/variable';

.asset-model-page {
  .image-link {
    text-decoration: underline;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    max-width: 14rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;
  }
}
