@import '../../../theme/common/variable';

.status-header {
  background-color: $main-color;
  color: #fff;
  padding: 0.75rem 0;

  &__value {
    color: #66ff66;
  }
}
