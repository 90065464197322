.admin-master-page {
  th {
    font-size: 0.8125rem;
    text-align: center;
  }

  .btn {
    min-width: inherit;
  }

  .btn-action {
    width: 100%;
    &:nth-child(2) {
      margin-top: 0.5rem;
    }

    @media (min-width: 1615px) {
      width: auto;
      &:nth-child(2) {
        margin-top: 0;
      }
    }
  }

  .asset-status-col1 {
    width: 50%;
    @media (min-width: 1400px) {
      width: 70%;
    }
  }

  .asset-status-col2 {
    width: 50%;
    @media (min-width: 1400px) {
      width: 30%;
    }
  }
}

.btn-action:disabled {
  color: #fff !important;
  background-color: #666 !important;
  border-color: #666 !important;
}
