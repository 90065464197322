.asset-model-form-modal {
  .file-name {
    display: inline-block;
    vertical-align: middle;
    max-width: 370px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;
  }
}
