@import '../../theme/common/variable';

.branch-menu {
  .branch-status {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .list-group-item {
    // border-bottom: none;
    position: relative;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    margin: 0.125rem 0;

    &:hover {
      background-color: $bg-main-color;
      border-radius: 0.25rem !important;
    }

    &.isSelected {
      background-color: $bg-main-color;
      border-radius: 0.25rem !important;

      &.menu-selected {
        background-color: $main-color;

        .branch-name .branch-link {
          color: #fff !important;
        }
      }
    }

    &.dt-parent {
      border-radius: 0.25rem !important;
    }

    &.dt-parent.isOpen:after {
      content: '';
      position: absolute;
      top: 2rem;
      left: 0.875rem;
      width: 1px;
      height: calc(100% - 1rem);
      background-color: $line-color;
      z-index: 1;
    }

    & > .branch-icon {
      padding: 0.125rem 0.625rem 0.375rem 0.5rem;
      cursor: pointer;
    }

    .branch-name {
      // flex: 1;
      display: inline-block;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      padding-left: 0.6875rem;
      padding-right: 0.6875rem;
      cursor: pointer;
      text-align: left;

      -webkit-appearance: none;
      appearance: none;
      border: none;
      outline: none;
      background: transparent;

      .branch-link {
        color: $main-color;
        margin-right: 0.5rem;
      }
    }

    &.dt-parent .branch-name {
      padding-left: 0;
      // flex: inherit;
    }

    .branch-status-wrapper {
      padding: 0.25rem 0.75rem 0.25rem 0.5rem;
    }
  }

  /*** level 1 ***/

  /*** level 2 ***/
  .list-group .list-group {
    margin-left: 1.4375rem;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -0.5625rem;
      width: 1px;
      height: calc(100% - 1.0625rem);
      background-color: $line-color;
      z-index: 1;
    }

    &:last-child::after {
      content: '';
      position: absolute;
      top: -1.0625rem;
      left: -2rem;
      width: 1px;
      height: 100%;
      background-color: #fff;
      z-index: 1;
    }

    .list-group-item {
      &::before {
        content: '';
        position: absolute;
        top: 1rem;
        left: -0.5rem;
        width: 0.5rem;
        height: 1px;
        background-color: $line-color;
        z-index: 1;
      }
    }
  }
}
