.ams-month-picker {
  &__button {
    svg {
      position: relative;
      top: -1px;
    }
  }

  .btn {
    min-width: inherit;
  }
}
