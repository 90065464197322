.btn {
  &--green {
    color: #000;
    background-color: #d5e8d4;
    border-color: #d5e8d4;

    .btn-check:checked + &,
    :not(.btn-check) + &:active,
    &:disabled,
    &:first-child:active,
    &.active,
    &.show,
    &:hover {
      color: #000;
      background-color: #b1c4b0;
      border-color: #b1c4b0;
    }
  }

  &--violet {
    color: #000;
    background-color: #e1d5e7;
    border-color: #e1d5e7;

    .btn-check:checked + &,
    :not(.btn-check) + &:active,
    &:disabled,
    &:first-child:active,
    &.active,
    &.show,
    &:hover {
      color: #000;
      background-color: #bdb1c3;
      border-color: #bdb1c3;
    }
  }

  &--yellow {
    color: #000;
    background-color: #fff2cc;
    border-color: #fff2cc;

    .btn-check:checked + &,
    :not(.btn-check) + &:active,
    &:disabled,
    &:first-child:active,
    &.active,
    &.show,
    &:hover {
      color: #000;
      background-color: #dbcea8;
      border-color: #dbcea8;
    }
  }

  &--red {
    color: #000;
    background-color: #f8cecc;
    border-color: #f8cecc;

    .btn-check:checked + &,
    :not(.btn-check) + &:active,
    &:disabled,
    &:first-child:active,
    &.active,
    &.show,
    &:hover {
      color: #000;
      background-color: #d4aaa8;
      border-color: #d4aaa8;
    }
  }

  &--white {
    color: #000;
    background-color: #fff;
    border-color: #fff;

    .btn-check:checked + &,
    :not(.btn-check) + &:active,
    &:first-child:active,
    &.active,
    &.show,
    &:hover {
      color: #000;
      background-color: #ededed;
      border-color: #ededed;
    }
  }

  &--blue {
    color: #000;
    background-color: #dae8fc;
    border-color: #dae8fc;

    .btn-check:checked + &,
    :not(.btn-check) + &:active,
    &:first-child:active,
    &.active,
    &.show,
    &:hover {
      color: #000;
      background-color: #c8d6ea;
      border-color: #c8d6ea;
    }
  }
}
