@import '../../../theme/common/variable';

.inspection-result-table {
  td {
    text-align: center;
    vertical-align: middle;
  }

  .state-selector {
    max-width: 60px;
    margin: 0 auto;
    background-color: $bg-blue-color;

    option {
      background-color: #fff;
    }

    &.abnormal {
      background-color: $bg-red-color;
    }
  }
}

.inspection-status-table {
  td {
    text-align: center;
    vertical-align: middle;
  }
  .row-inspection-status-com {
    background-color: #fff1ad;
  }
  .row-inspection-status-area {
    background-color: #fff5cf;
  }
  .row-inspection-status-block {
    background-color: #fffaed;
  }
  .row-inspection-status-unit {
    background-color: #fbf8f1;
  }
  .row-inspection-status-store {
    background-color: #ffffff;
  }
}
