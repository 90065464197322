@import './theme/common/variable';

/* The following block can be included in a custom.scss */

/* make the customizations */
$blue: #36489a !default;
$gray-100: #f2f2f2 !default;

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';
@import './theme/common/stickyTable';
@import './theme/common/layout';
@import './theme/common/background';
@import './theme/common/button';
@import './theme/common/reactDatePicker';

html {
  font-size: 12px;
}

.btn {
  min-width: 6rem;
}

/** custom Accordion **/
.accordion-button {
  position: relative;
  background-color: var(--bs-accordion-active-bg);
  padding-left: 2.75rem;

  &::before {
    content: '';
    position: absolute;
    top: 1.125rem;
    left: 1.25rem;
    width: 1rem;
    height: 1rem;
    background-color: $main-color;
    border-radius: 50%;
  }
}

.accordion-button:focus {
  box-shadow: none !important;
}
