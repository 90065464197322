@import '../../../theme/common/variable';

.action-list {
  display: flex;
}
.sidebar {
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  border-right: 1px solid #ccc;
}
.register-view {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.history-view {
  max-height: 100px;
  height: 100px;
  overflow: auto;
  border-radius: 10px;
  border: solid 1px $border-color;
}
.history {
  display: table;
  width: 100%;
  padding: 10px;
}
.history > * {
  display: table-row;
}
.history .history-col {
  display: table-cell;
}

.update-history-view {
  margin-top: 5px !important;
  display: flex;
  justify-content: flex-end;
}
.row-inventory-status-com {
  background-color: #fff1ad;
}
.row-inventory-status-area {
  background-color: #fff5cf;
}
.row-inventory-status-block {
  background-color: #fffaed;
}
.row-inventory-status-unit {
  background-color: #fbf8f1;
}
.row-inventory-status-store {
  background-color: #ffffff;
}
.clickable-item {
  cursor: pointer;
  color: $main-color;
  text-decoration: underline;
}

.inventory-result-table,
.inventory-status-table {
  td {
    text-align: center;
    vertical-align: middle;
  }
}
