$main-color: #36489a !important;

$line-color: #dee2e6 !important;

$border-color: #ced4da !important;

$bg-main-color: #ebedf5 !important;
$bg-green-color: #d5e8d4 !important;
$bg-violet-color: #e1d5e7 !important;
$bg-yellow-color: #fff2cc !important;
$bg-red-color: #f8cecc !important;
$bg-blue-color: #dae8fc !important;
