.leih-page {
  .search-month {
    max-width: 200px;
  }

  th,
  td {
    text-align: center;
  }
}
