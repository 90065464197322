.sticky-table {
  overflow: auto;
  height: fit-content;
  border-bottom: 1px solid $table-border-color;
  border-right: 1px solid $table-border-color;
  background-color: #999;

  table {
    min-width: fit-content;
    border-collapse: separate;
    border-spacing: 0;
    margin-bottom: 0;
    background-color: #fff;
    border-left: 1px solid $table-border-color;
  }

  tbody tr:last-child {
    td {
      border-bottom: none;
    }
  }

  th {
    position: sticky;
    top: 0px;
    background-color: #ebedf5;
    border-top: 1px solid $table-border-color;
    text-align: center;

    &.sort {
      cursor: pointer;
    }
  }

  th,
  td {
    border-bottom: 1px solid $table-border-color;
    border-right: 1px solid $table-border-color;
    white-space: nowrap;
  }
}

.div-table {
  display: table;
  width: 100%;
  border-bottom: 1px solid $table-border-color;

  min-width: fit-content;
  background-color: #fff;
  position: relative;
  z-index: 10;
}

.div-tr {
  display: table-row;
  background-color: #fff;

  &.table-secondary {
    background-color: #e2e3e5;
  }

  &:hover .div-td {
    background-color: rgba(0, 0, 0, 0.075);
  }
}

.div-thead {
  background-color: #eee;
  display: table-header-group;
  font-weight: bold;
}

.div-td,
.div-th {
  border-left: 1px solid $table-border-color;
  border-top: 1px solid $table-border-color;
  display: table-cell;
  padding: 0.5rem 0.5rem;
}

.div-tr:last-child {
  border-bottom: 1px solid $table-border-color;
}

.div-td:last-child {
  border-right: 1px solid $table-border-color;
}

.div-th {
  position: sticky;
  top: 0px;
  background-color: #ebedf5;
  text-align: center;
  padding-right: 16px;

  &.sort {
    cursor: pointer;
  }

  &:last-child {
    border-right: 1px solid $table-border-color;
  }
}

.div-tbody {
  display: table-row-group;
}

.fixed-size-list > div {
  border-right: 1px solid $table-border-color;
  background-color: #fff;
}
