.asset {
  .button-search {
    display: flex;
    justify-content: center;
  }
}
.fix-padding {
  padding-left: 7rem;
}
.fix-padding-current-status {
  padding-right: 15rem;
}
