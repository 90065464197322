.bg-main {
  background-color: $bg-main-color;
}

.bg-green {
  background-color: $bg-green-color;
}

.bg-red {
  background-color: $bg-red-color;
}

.bg-yellow {
  background-color: $bg-yellow-color;
}

.bg-violet {
  background-color: $bg-violet-color;
}
