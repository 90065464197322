input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 7px;
  cursor: pointer;
  background-color: #36489a !important;
  color:#fff;
  border-radius: 0.25rem;
}